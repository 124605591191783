import UP_SDK from "@urbanpiper-engineering/upsdk";
import cloneDeep from "external/helpers/deepClone";

import { store } from "../store";
import { SET_PAYLOAD } from "../store/actions/upsdk";

import Axios from "axios";
import Item from "../store/models/Item";
import Store from "../store/models/Store";
import upsdkSelector from "../store/selectors/upsdkSelector";
import configSelector from "../store/selectors/configSelector";
import analyticsPubSub, { ANALYTICS_EVENTS } from "./analyticsPubSub";
import dayjs from "dayjs";
import { USER_LOGOUT } from "../store/actions/auth";
import LanguageHelper from "../helpers/language.helper";
import { ORDERING_TYPE_QR } from "../../constants/enums.constants";
import graphqlService from "./graphql/graphqlService";

const PAYLOAD_STORAGE = "PAYLOAD";

let checkoutSession = null;

function startSubscribers() {
  UP_SDK.subscribe(UP_SDK.events.onPayloadChange, (response) => {
    let data = cloneDeep(response.data);
    //store in localStorage
    localStorage.setItem(PAYLOAD_STORAGE, JSON.stringify(data));
    store.dispatch({ type: SET_PAYLOAD, data: response.data });
  });

  UP_SDK.subscribe(UP_SDK.events.onAuthorizationError, (response) => {
    let url = response.data.config.url;
    if (url.includes("auth/token")) return;
    if (url.includes("auth/otp/login")) return;
    if (url.includes("auth/otp/signup")) return;
    if (url.includes("auth/otp/validate")) return;

    store.dispatch({ type: USER_LOGOUT, data: null });
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  });
}

function init(SDK_CONFIG) {
  let config = window.ROOT_CONFIG;
  let payload = localStorage.getItem(PAYLOAD_STORAGE);
  let language = LanguageHelper.getLanguage(config);
  let channel =
    configSelector.getMode({ config }) === ORDERING_TYPE_QR
      ? "in_store"
      : "web";

  if (payload) {
    let payloadJson = JSON.parse(payload);
    const auth_token = upsdkSelector.getAuthHeader({ upsdk: payloadJson });
    if (auth_token) {
      setAuthHeader(auth_token);
    }
  }

  let finalConfig = {
    ...SDK_CONFIG,
    LANG: language,
    CHANNEL: channel,
    SUB_PATH: configSelector.getSubPathWithSlash({ config }),
  };

  // proxy the api url for netlify deployment on production environment
  if (window.NETLIFY && window.ROOT_CONFIG.biz.env === "production") {
    UP_SDK.setBaseURL("/api/");
  }

  UP_SDK.init(finalConfig);
  checkoutSession = null;
  createCheckoutSession({});
  if (payload) {
    let payloadJson = JSON.parse(payload);
    const setPayload = checkoutSession.setPayload(payloadJson);
    if (setPayload.success) {
      store.dispatch({ type: SET_PAYLOAD, data: setPayload.data });
    }
  }
  startSubscribers();

  const startOfHour = dayjs().startOf("hour").format("hh:mm");
  const endOfHour = dayjs().endOf("hour").format("hh:mm");
  const intervalOfDay = `${startOfHour} - ${endOfHour}`;

  const eventObj = {
    interval_of_day: intervalOfDay,
  };
  analyticsPubSub.publish(ANALYTICS_EVENTS.APP_LAUNCHED, eventObj);
}

function updateConfig(config) {
  UP_SDK.updateConfig(config);
}

function createCheckoutSession(
  params = {
    fullfilmentmode: "pickup",
  },
) {
  checkoutSession = UP_SDK.checkoutSession(params);
}

function getBanners(location_id) {
  return UP_SDK.getImages("web_banner", location_id);
}

function getRecommendedItems(items = [0], location_id = null) {
  return checkoutSession
    .getRecommendedItems({ items: items, location_id })
    .then((response) => {
      let data = response.data.map((item) => {
        return new Item(item);
      });

      data = data.sort((a, b) => {
        if (a.current_stock === 0 && b.current_stock !== 0) {
          return 1;
        } else if (b.current_stock === 0 && a.current_stock !== 0) {
          return -1;
        }

        return a.sort_order - b.sort_order;
      });

      return data;
    })
    .catch(() => true);
}

function getCatalogue(sortBy = null, filters = null) {
  //TODO: move to UPSDK
  const categoriesPromise = checkoutSession.getStoreCategories();
  const itemsPromise = checkoutSession.getAllItems({
    sort_by: sortBy,
    filters: filters,
  });
  //get all options
  const optionsPromise = checkoutSession.getFilterSortOptions({
    category_id: 0,
  });

  function sortItems(items) {
    let data = [];
    let outOfStock = [];
    items.forEach((item) => {
      if (item.current_stock === 0) {
        outOfStock.push(item);
      } else {
        data.push(item);
      }
    });
    if (!sortBy) {
      data.sort((a, b) => a.sort_order - b.sort_order);
    }
    return [...data, ...outOfStock];
  }

  return Promise.all([categoriesPromise, itemsPromise, optionsPromise])
    .then((results) => {
      if (results.length < 3) {
        throw { error_message: "Request Failed" };
      }
      const categoryResponse = results[0].data;
      const itemData = results[1].data.items.map((item) => new Item(item));
      const optionsData = results[2].data;
      const categoryData = [];

      //remove all empty categories
      categoryResponse.forEach((category) => {
        let sub_categories = [];
        let categoryItems = itemData.filter(
          (item) => item.category.id === category.id,
        );
        category.items = sortItems(categoryItems);
        category.item_count = categoryItems.length;

        category.sub_categories &&
          category.sub_categories.forEach((sub_category) => {
            let items = itemData.filter(
              (item) => item.category.id === sub_category.id,
            );
            sub_category.items = sortItems(items);
            sub_category.item_count = items.length;

            if (items && items.length > 0) {
              sub_categories.push(sub_category);
            }
          });

        if (
          (categoryItems && categoryItems.length > 0) ||
          (sub_categories && sub_categories.length > 0)
        ) {
          category.sub_categories = sub_categories;
          categoryData.push(category);
        }
      });

      return {
        items: itemData,
        categories: categoryData,
        options: optionsData,
        appliedOptions: { sort_by: sortBy, filters: filters },
      };
    })
    .catch((e) => {
      console.log(e);
    });
}

function searchItems(keyword) {
  return checkoutSession.searchItems({ keyword: keyword }).then((response) => {
    let data = response.data.items.map((item) => {
      return new Item(item, false, false, true, false, false);
    });

    //TODO: move to UPSDK
    data = data.sort((a, b) => {
      if (a.current_stock === 0 && b.current_stock !== 0) {
        return 1;
      } else if (b.current_stock === 0 && a.current_stock !== 0) {
        return -1;
      }

      return a.sort_order - b.sort_order;
    });

    let selectedStore = getSelectedStore();

    const eventObj = {
      query: keyword,
      store: (selectedStore && selectedStore.name) || "NA",
      search_results: data,
    };
    analyticsPubSub.publish(ANALYTICS_EVENTS.ITEM_SEARCH, eventObj);

    return data;
  });
}

function updateCheckoutSession({ fullfilmentmode, phone, payment_option }) {
  return checkoutSession.update({ fullfilmentmode, phone, payment_option });
}

function setFulfilmentType(type) {
  updateCheckoutSession({ fullfilmentmode: type });
}

function addToCart(itemObj, qty) {
  let item_price = itemObj.item_price || 0;

  if (itemObj.options) {
    itemObj.options.forEach((option) => {
      item_price = item_price + option.price || 0;
    });
  }

  const cart = upsdkSelector.getCart(store.getState());
  if (cart.total === 0) {
    const cartInitObj = {
      id: itemObj.id,
      item_title: itemObj.name || itemObj.item_title,
      item_category_title: (itemObj.category && itemObj.category.name) || "",
      qty: itemObj.quantity || 1,
      item_price: item_price,
    };
    analyticsPubSub.publish(ANALYTICS_EVENTS.CART_INIT, cartInitObj);
  }

  const eventObj = {
    id: itemObj.id,
    item_price: item_price,
    item_title: itemObj.name || itemObj.item_title,
    item_category_title: (itemObj.category && itemObj.category.name) || "",
    qty: qty,
    is_recommended: itemObj.isRecommended,
    is_upsold: itemObj.isUpsold,
    from_search: itemObj.from_search,
    from_detail_scr: itemObj.from_detail_scr,
    from_checkout: itemObj.from_checkout,
  };

  analyticsPubSub.publish(ANALYTICS_EVENTS.PRODUCT_CLICKED, eventObj);
  analyticsPubSub.publish(ANALYTICS_EVENTS.ADD_TO_CART, eventObj);
  checkoutSession.addToCart(itemObj, qty);
  getUserDetails()
    .then(() => {
      const upsdkState = store.getState();
      graphqlService.updateCart({
        items: checkoutSession.getCart(),
        channel: upsdkSelector.getChannel(upsdkState),
        fulfillment_mode: upsdkSelector.getFullfilment(upsdkState),
        location_name: upsdkSelector.getStore(upsdkState)?.name,
        city: upsdkSelector.getStore(upsdkState)?.city,
      });
    })
    .catch((err) => {
      //do nothing
    });
}

function removeFromCart(item) {
  let item_price = item.item_price || 0;

  const eventObj = {
    item_id: item.id,
    item_title: item.item_title,
    item_category_title: (item.category && item.category.name) || "",
    item_price: item_price,
    qty: 1,
  };
  analyticsPubSub.publish(ANALYTICS_EVENTS.REMOVE_FROM_CART, eventObj);

  const remove = checkoutSession.removeFromCart(item.sig || parseInt(item.id));
  getUserDetails()
    .then(() => {
      const upsdkState = store.getState();
      graphqlService.updateCart({
        items: checkoutSession.getCart(),
        channel: upsdkSelector.getChannel(upsdkState),
        fulfillment_mode: upsdkSelector.getFullfilment(upsdkState),
        location_name: upsdkSelector.getStore(upsdkState)?.name,
        city: upsdkSelector.getStore(upsdkState)?.city,
      });
    })
    .catch(() => {
      //do nothing
    });

  return remove;
}

function getAllStores(filterClosedStores = true) {
  return UP_SDK.getAllStores().then((response) => {
    if (response.data) {
      let stores = [];
      response.data.forEach((store) => {
        //TODO: move to UPSDK
        if (filterClosedStores) {
          if (store.hide_store_name || !store.enabled_for_ordering) {
            return;
          }
        }

        stores.push(new Store(store));
      });
      return stores;
    } else {
      return [];
    }
  });
}

function getStoreById(locationId, saveStore = true) {
  return getAllStores(false)
    .then((allStores) => {
      const store = allStores.find((store) => {
        return store.biz_location_id === Number(locationId);
      });

      if (store && saveStore) {
        selectStore(store);
      }

      return store;
    })
    .catch((error) => {
      throw error;
    });
}

function selectStore(storeObj, selectedFulfilmentType) {
  try {
    checkoutSession.setStore(storeObj);
    // resetCartDetails();
    const eventObj = {
      pickup_store_name: storeObj.name,
    };
    if (selectedFulfilmentType === "pickup") {
      analyticsPubSub.publish(ANALYTICS_EVENTS.PICKUP_STORE_SELECTED, eventObj);
    }
  } catch (error) {
    console.log({ selectStoreError: error });
  }
}

function refreshSelectedStore(selectedStore, fulfillment_type) {
  const storeId = selectedStore?.id;
  const response = getStoreById(storeId);
  if (!response) {
    const lat = selectedStore.lat;
    const lng = selectedStore.lng;
    UP_SDK.getStore({ lng, lat, fulfillment_type })
      .then((res) => {
        const targetStore = res.data.store;
        if (targetStore) {
          const store = new Store(targetStore);
          checkoutSession.setStore(store);
        }
      })
      .catch((err) => console.log(err));
  }
}

function removeStore() {
  try {
    checkoutSession.removeStore();
  } catch (error) {
    console.log({ removeStore: error });
  }
}

function getSelectedStore() {
  return checkoutSession.getSelectedStore();
}

/**
 * Login with otp
 */
function login(isd_code, phone) {
  return UP_SDK.v2
    .loginWithOTP({
      isd_code,
      phone,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Login with email
 */
function loginWithEmail(email) {
  return UP_SDK.v2
    .loginWithEmail({
      email,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Logout the current user
 */
function logout(phone) {
  const eventObj = {
    username: phone,
  };

  analyticsPubSub.publish(ANALYTICS_EVENTS.LOGOUT, eventObj);
  return UP_SDK.v2.logout();
}

/**
 *
 * Submit OTP (for loginWithOTP and createUserWithOTP)
 *
 */
function submitOTP(isd_code, phone, otp) {
  return UP_SDK.v2.submitOTP({ isd_code, phone, otp });
}

/**
 *
 * Submit OTP (for loginWithEmailOTP)
 *
 */
function submitEmailOTP(email, otp) {
  return UP_SDK.v2.submitEmailOTP({ email, otp });
}

/**
 * Login with phone number and password
 */
function getOTPViaCall(isd_code, phone) {
  return UP_SDK.v2
    .getOTPViaCall({
      isd_code,
      phone,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

/**
 * Create a new user
 */
function signup({
  isd_code,
  phone,
  first_name,
  last_name,
  email,
  receive_promo,
  referralData,
}) {
  analyticsPubSub.publish(ANALYTICS_EVENTS.SIGNUP_START, {
    username: phone,
    social_auth: "NA",
  });

  return UP_SDK.v2.createUserWithOTP({
    isd_code,
    phone,
    first_name,
    last_name,
    email,
    receive_promo,
    referral: Object.keys(referralData).length > 0 ? referralData : null,
  });
}

/**
 * Create a user for guest checkout
 */
function createUserForGuestCheckout({ phone, name, email }) {
  return UP_SDK.v2.createUserForGuestCheckout({ name, phone, email });
}

/**
 * Verify phone number
 */
function verifyPhone(params) {
  const { customer_phone, otp, provider, token, email } = params;

  let promise;
  if (provider) {
    promise = UP_SDK.v2.verifyPhoneAfterSocialLogin({
      provider,
      accessToken: token,
      email,
      phone: customer_phone,
      token: otp,
    });
  } else {
    promise = UP_SDK.v2.verifyPhone({
      phone: customer_phone,
      token: otp,
    });
  }

  return promise.then((data) => {
    if (!provider) {
      analyticsPubSub.publish(ANALYTICS_EVENTS.SIGNUP_COMPLETE, {
        username: customer_phone,
        social_auth: "NA",
      });
    } else {
      analyticsPubSub.publish(ANALYTICS_EVENTS.SOCIAL_LOGIN_SIGNUP_COMPLETE, {
        username: customer_phone,
        social_auth: provider === "google" ? 1 : 2,
      });
    }

    return data;
  });
}

/**
 * Resend OTP
 */
function resendOTP(phone) {
  return UP_SDK.v2
    .resendOtp({
      phone: phone,
    })
    .then((data) => {
      analyticsPubSub.publish(ANALYTICS_EVENTS.RESEND_OTP, {
        username: phone,
      });

      return data;
    });
}

/**
 * Resend Email OTP
 */
function resendEmailOTP(email) {
  return UP_SDK.v2
    .resendEmailOTP({
      email,
    })
    .then((data) => {
      analyticsPubSub.publish(ANALYTICS_EVENTS.RESEND_OTP, {
        username: email,
      });

      return data;
    });
}

function makeBill(applyWalletCredit = false, updateCart = false, dashDelivery) {
  return checkoutSession.makeBill(applyWalletCredit, updateCart, dashDelivery);
}

function addCoupon({
  coupon,
  phone,
  delivery_datetime = null,
  time_slot = null,
  apply_wallet_credit = false,
  isSuggested = false,
  isPreSelected = false,
}) {
  return new Promise(function (resolve, reject) {
    return checkoutSession
      .addCoupon({
        coupon,
        phone,
        apply_wallet_credit,
        delivery_datetime,
        time_slot,
      })
      .then((response) => {
        const data = response.data;
        if (data) {
          const eventObj = {
            success: data.success,
            discount: data.value || 0,
            coupon_code: coupon,
            is_suggested: isSuggested,
            pre_selected: isPreSelected,
          };
          if (data.success) {
            analyticsPubSub.publish(ANALYTICS_EVENTS.COUPON_SUCCESS, eventObj);
          } else {
            analyticsPubSub.publish(ANALYTICS_EVENTS.COUPON_FAILED, eventObj);
          }
        }
        resolve(response);
      })
      .catch((e) => {
        const data = e.data;
        if (data) {
          const eventObj = {
            success: data.success,
            discount: data.value || 0,
            coupon_code: coupon,
            is_suggested: isSuggested,
            pre_selected: isPreSelected,
          };
          analyticsPubSub.publish(ANALYTICS_EVENTS.COUPON_FAILED, eventObj);
        }
        reject(e);
      });
  });
}

function getDeliverableAddresses() {
  return checkoutSession.getDeliverableAddress().then((res) => {
    if (res.data && res.data.addresses) {
      const data = res.data.addresses?.filter(
        (address) => address.deliverable === true,
      );
      return {
        success: true,
        data: {
          addresses: data,
        },
      };
    } else {
      return {
        success: false,
        error_message: "Deliverable address not found",
      };
    }
  });
}

function getItemDetails(item_id) {
  return checkoutSession.getItemDetails({ item_id }).then((response) => {
    let item = new Item(response.data);
    const eventObj = {
      item_id: item.id,
      item_title: item.name,
      item_price: item.price,
      item_category: item.category ? item.category.name : null,
      item_thumbnail: item.thumbnail,
    };
    analyticsPubSub.publish(ANALYTICS_EVENTS.PRODUCT_CLICKED, eventObj);
    return item;
  });
}

function getCoupons() {
  return UP_SDK.getCoupons({ limit: 100 }).then((response) => {
    return response.data.coupons;
  });
}

function removeCoupon() {
  checkoutSession.removeCoupon();
}

function getItemOptions(item_id, location_id) {
  return checkoutSession.getItemOptions({ item_id, location_id });
}

function changePassword({ oldPassword, newPassword, confirmPassword, phone }) {
  return new Promise(function (resolve, reject) {
    return UP_SDK.changePassword({
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      phone: phone,
    })
      .then((response) => {
        const eventObj = {
          username: phone,
          pwd_changed: true,
        };
        analyticsPubSub.publish(ANALYTICS_EVENTS.PROFILE_UPDATED, eventObj);
        resolve(response);
      })
      .catch((e) => {
        const eventObj = {
          username: phone,
          pwd_changed: false,
        };
        analyticsPubSub.publish(ANALYTICS_EVENTS.PROFILE_UPDATED, eventObj);
        reject(e);
      });
  });
}

function getOrderHistory() {
  return UP_SDK.getOrderHistory().then((response) => {
    return response.data.orders;
  });
}

function getOrderDetails(id) {
  return UP_SDK.getOrderDetail({ id: id }).then((response) => {
    return response.data;
  });
}

function setAuthHeader(token) {
  return UP_SDK.authManager.init(token);
}

function getUserDetails() {
  return UP_SDK.getUserInfo();
}

function getUserLikes() {
  return UP_SDK.getUserLikes();
}

function likeItem(item) {
  return UP_SDK.likeItem({ id: item.id }).then((response) => {
    const eventObj = { item_title: item.name, like: true };
    analyticsPubSub.publish(ANALYTICS_EVENTS.ITEM_LIKED, eventObj);
    return response;
  });
}

function unlikeItem(item) {
  return UP_SDK.unlikeItem({ id: item.id }).then((response) => {
    const eventObj = { item_title: item.name, like: false };
    analyticsPubSub.publish(ANALYTICS_EVENTS.ITEM_LIKED, eventObj);
    return response;
  });
}

function createCompoundItem(itemObj) {
  return checkoutSession.createCompoundItem(itemObj);
}

function getDeliverableStores(lat, lng, subLocality, fulfillment_type) {
  // analyticsPubSub.publish(ANALYTICS_EVENTS.ADDRESS_SELECTED);
  return UP_SDK.getStore({
    lat,
    lng,
    fulfillment_type,
  }).then((response) => {
    let nearestStore = null;
    let optional_stores = [];
    if (response.data.store) {
      nearestStore = new Store(response.data.store);
    }

    if (response.data.optional_stores) {
      optional_stores = response.data.optional_stores.map(
        (store) => new Store(store),
      );
    }

    if (!nearestStore) {
      const eventObj = {
        lat: lat,
        lng: lng,
        addr_str: subLocality,
      };
      analyticsPubSub.publish(ANALYTICS_EVENTS.NO_STORES_NEARBY, eventObj);
    } else {
      const eventObj = {
        lat: lat,
        lng: lng,
        store: nearestStore.name,
      };
      analyticsPubSub.publish(
        ANALYTICS_EVENTS.DELIVERY_STORE_SELECTED,
        eventObj,
      );
      if (nearestStore.temporarily_closed) {
        const closedEventObj = {
          ...eventObj,
          date: dayjs().format("YYYY-MM-dd"),
          time: dayjs().format("hh:mm a"),
        };
        analyticsPubSub.publish(
          ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TEMP,
          closedEventObj,
        );
      }
      if (nearestStore.closing_day) {
        const closedEventObj = {
          ...eventObj,
          date: dayjs().format("YYYY-MM-dd"),
          time: dayjs().format("hh:mm a"),
        };
        analyticsPubSub.publish(
          ANALYTICS_EVENTS.NEAREST_STORE_CLOSED_TODAY,
          closedEventObj,
        );
      }
    }

    return {
      store: nearestStore,
      optional_stores: optional_stores,
    };
  });
}

function getTransactions() {
  return UP_SDK.getWalletTransactions();
}

function placeOrder({
  phone,
  user_phone,
  payment_option,
  delivery_datetime,
  instructions,
  time_slot,
  pg_service,
  primaryColor,
  userData,
  is_future_order,
}) {
  const params = {
    phone,
    user_phone,
    payment_option,
    delivery_datetime,
    instructions,
    pg_service,
    primaryColor,
    userData,
    is_future_order,
  };

  if (time_slot) {
    params.time_slot_end = time_slot.end_time;
    params.time_slot_start = time_slot.start_time;
  }

  return checkoutSession
    .placeOrder(params)
    .then((response) => {
      console.log(response, "placing order");

      return response;
    })
    .catch((error) => {
      console.log(error, "placing order");

      throw error;
    });
}

function saveAddress(newAddress) {
  return UP_SDK.saveUserAddress(newAddress);
}

function deleteAddress(id) {
  return UP_SDK.deleteAddress({
    id: id,
  });
}

function updateAddress(
  id,
  lat,
  lng,
  subLocality,
  address1,
  landmark,
  city,
  pin,
  tag,
) {
  return UP_SDK.updateUserAddress({
    id: id,
    lat: lat,
    lng: lng,
    sub_locality: subLocality,
    address_1: address1,
    address_2: landmark || "",
    landmark: landmark || "",
    city: city || "",
    pin: pin,
    tag: tag,
  });
}

function updateUserProfile(
  name,
  phone,
  email,
  birthday,
  aniversary,
  gender,
  recv_promo = false,
) {
  var userInfo = {
    name: name,
    phone: phone,
    email: email,
  };

  if (gender) {
    userInfo.gender = gender;
  }

  if (birthday) {
    userInfo.birthday = Date.parse(birthday);
  }

  if (aniversary) {
    userInfo.anniversary = Date.parse(aniversary);
    userInfo.aniversary = userInfo.anniversary;
  }

  userInfo.recv_promo = recv_promo;

  return UP_SDK.setUserInfo(userInfo);
}
function getCart() {
  return checkoutSession.getCart();
}

function setCart(cartObj = {}) {
  checkoutSession.setCart(cartObj);
}

function updateCartOnLanguageChange() {
  //should be moved to upsdk/meraki-api
  const currentCart = cloneDeep(checkoutSession.getCart()?.data);
  const currentItems = currentCart.items;
  if (!currentItems.length) {
    return;
  }
  for (let i = 0; i < currentItems.length; i++) {
    const item_id = currentItems[i].id;
    checkoutSession
      .getItemDetails({ item_id })
      .then((response) => new Item(response.data))
      .then(({ name, option_groups, description }) => {
        currentItems[i].item_title = name;
        currentItems[i].item_desc = description;
        if (currentItems[i].has_options) {
          const currentOptionGroups = currentItems[i].options;
          const updatedOptionGroups = option_groups;

          currentOptionGroups.forEach((currentOption) => {
            updatedOptionGroups?.forEach((optionGroup) => {
              const options = optionGroup.options;
              options.forEach((item) => {
                if (currentOption.id === item.id) {
                  currentOption.title = item.title;
                }
              });
            });
          });
        }
      })
      .catch((err) => console.log(err));
  }
  currentCart.items = currentItems;
  setCart(currentCart);
}

function clearCart(clearAcrossDevices = false) {
  let newCartObj = { items: [], total: 0, totalItemsCount: 0 };
  if (clearAcrossDevices) {
    graphqlService.clearCart();
  }
  checkoutSession.setCart(newCartObj);
}

function setPGConfig(type, options) {
  return UP_SDK.setPGConfig(type, options);
}

function reloadWallet(amount, primaryColor) {
  let paymentMode = "payment_gateway";
  const eventObj = {
    amount: amount,
    payment_mode: paymentMode,
  };

  analyticsPubSub.publish(ANALYTICS_EVENTS.WALLET_RELOAD_INIT, eventObj);
  return checkoutSession
    .reloadWallet(amount, primaryColor)
    .then((response) => {
      const eventObj = {
        success: response.success || false,
        amount: amount,
        payment_mode: paymentMode,
      };

      if (response.success) {
        analyticsPubSub.publish(
          ANALYTICS_EVENTS.SUCCESSFUL_WALLET_RELOAD,
          eventObj,
        );
      } else {
        analyticsPubSub.publish(
          ANALYTICS_EVENTS.FAILED_WALLET_RELOAD,
          eventObj,
        );
      }
      return response;
    })
    .catch((err) => err);
}

async function socialLogin(params) {
  const { name, email, provider, accessToken } = params;
  return new Promise(function (resolve, reject) {
    socialLoginAPICall(email, provider, accessToken, name)
      .then((response) => {
        const newResponse = {
          ...response,
          name: name,
          email: email,
          provider: provider,
          token: accessToken,
        };
        if (response.success) {
          const eventObj = {
            ...response,
            success: true,
            username: response.phone,
            social_auth: provider === "google" ? 1 : 2,
          };
          analyticsPubSub.publish(
            ANALYTICS_EVENTS.SOCIAL_LOGIN_SUCCESS,
            eventObj,
          );
        }
        resolve(newResponse);
      })
      .catch((error) => {
        const eventObj = {
          success: false,
          username: "NA",
          social_auth: provider === "google" ? 1 : 2,
        };
        analyticsPubSub.publish(ANALYTICS_EVENTS.SOCIAL_LOGIN_FAILED, eventObj);
        reject(error);
      });
    // .finally((e) => googleLoginSignOut());
  });
}

//TODO: move to UPSDK
function socialLoginAPICall(email, provider, accessToken, name) {
  const baseUrl = configSelector.getAPIBase({ config: window.ROOT_CONFIG });
  const URL = baseUrl + "/api/v2/social_auth/me/";
  const params = {
    name: name,
    email: email,
    provider: provider,
    access_token: accessToken,
  };
  const requestConfig = {
    method: "get",
    url: URL,
    params: params,
    headers: {
      "Content-Type": "application/json",
      Authorization: window.ROOT_CONFIG.biz.username,
    },
    responseType: "json",
  };

  return Axios(requestConfig)
    .then((response) => {
      setAuthHeader(response.data.token);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });
}

//TODO: move to UPSDK
function checkPhone({ name, email, phone, provider, accessToken }) {
  const baseUrl = configSelector.getAPIBase({ config: window.ROOT_CONFIG });
  const URL = baseUrl + "/api/v2/social_auth/me/";
  const params = {
    name: name,
    email: email,
    provider: provider,
    access_token: accessToken,
    phone: phone,
    action: "check_phone",
  };
  const requestConfig = {
    method: "get",
    params: params,
    url: URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: window.ROOT_CONFIG.biz.username,
    },
    responseType: "json",
  };
  return Axios(requestConfig).then((response) => {
    return response.data;
  });
}

//TODO: move to UPSDK
function submitForm(data) {
  const baseUrl = configSelector.getAPIBase({ config: window.ROOT_CONFIG });
  const URL = baseUrl + `/biz/${window.ROOT_CONFIG.biz.id}/contact/`;

  const requestConfig = {
    method: "post",
    data: data,
    url: URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: window.ROOT_CONFIG.biz.username,
    },
    responseType: "json",
  };
  return Axios(requestConfig).then((response) => {
    return response.data;
  });
}

function reorder({ id }) {
  return checkoutSession.reorder({ id }).then((response) => {
    analyticsPubSub.publish(ANALYTICS_EVENTS.REORDER, {
      amount: response.data.order_total,
      items_available: response.data.items_available,
    });
    return response;
  });
}

function updateCartOnLogin() {
  const upsdkState = store.getState();

  graphqlService.updateCart({
    items: checkoutSession.getCart(),
    channel: upsdkSelector.getChannel(upsdkState),
    fulfillment_mode: upsdkSelector.getFullfilment(upsdkState),
    location_name: upsdkSelector.getStore(upsdkState)?.name,
    city: upsdkSelector.getStore(upsdkState)?.city,
  });
}

function setDeliveryAddress(data) {
  return checkoutSession.setAddress({
    ...data,
    address_id: data.id || data.address_id,
  });
}

function removeDeliveryAddress() {
  return checkoutSession.removeSelectedAddress();
}

function resolveCartValidationError(errors) {
  return checkoutSession.resolveCartValidationError(errors);
}

function setGuestPhone(phone) {
  checkoutSession.update({ phone: phone });
}

function setLanguage(lang) {
  UP_SDK.setLang({ lang: lang });
}

function submitFeedback(payload) {
  return checkoutSession.submitRating(payload);
}

function checkPaymentStatus(callback) {
  return UP_SDK.checkPaymentStatus(callback);
}
function checkWalletReload(callback) {
  return UP_SDK.checkWalletReload(callback);
}

function placeOrderCall(orderPayload) {
  return checkoutSession.placeOrderCall(orderPayload);
}

function getDeliveryEstimate(location_id, address_id) {
  return checkoutSession
    .getDeliveryEstimate({ location_id, address_id })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

const upsdkService = {
  init,
  updateConfig,
  getBanners,
  getCatalogue,
  getRecommendedItems,
  searchItems,
  updateCheckoutSession,
  setFulfilmentType,
  addToCart,
  removeFromCart,
  updateCartOnLanguageChange,
  getAllStores,
  refreshSelectedStore,
  selectStore,
  removeStore,
  getSelectedStore,
  getStoreById,
  login,
  signup,
  submitOTP,
  verifyPhone,
  getOTPViaCall,
  logout,
  makeBill,
  addCoupon,
  getDeliverableAddresses,
  getItemDetails,
  getCoupons,
  removeCoupon,
  getItemOptions,
  changePassword,
  resendOTP,
  setAuthHeader,
  getUserDetails,
  getOrderHistory,
  getOrderDetails,
  getUserLikes,
  likeItem,
  unlikeItem,
  createCompoundItem,
  getDeliverableStores,
  getTransactions,
  placeOrder,
  saveAddress,
  updateAddress,
  deleteAddress,
  updateUserProfile,
  clearCart,
  getCart,
  setCart,
  setPGConfig,
  reloadWallet,
  socialLogin,
  checkPhone,
  reorder,
  setDeliveryAddress,
  removeDeliveryAddress,
  createUserForGuestCheckout,
  resolveCartValidationError,
  setGuestPhone,
  setLanguage,
  submitFeedback,
  checkPaymentStatus,
  placeOrderCall,
  checkWalletReload,
  submitForm,
  getDeliveryEstimate,
  updateCartOnLogin,
  loginWithEmail,
  resendEmailOTP,
  submitEmailOTP,
};

export default upsdkService;
