export default function bannerChooser(bannerArray) {
  const mobileBanner = bannerArray?.filter((banner) =>
    banner.img_type.split(",").includes("mobile_web_banner"),
  );

  const desktopBanner = bannerArray.filter((banner) =>
    banner.img_type.split(",").includes("web_banner"),
  );

  const screenWidth = window.innerWidth;
  if (screenWidth > 0 && screenWidth < 768) {
    if (mobileBanner.length === 0) {
      return desktopBanner;
    }
    return mobileBanner;
  } else {
    return desktopBanner;
  }
}
