import { gql } from "@apollo/client";

const SEARCH_AUTO_COMPLETE = gql`
  query getPlacesSuggestions(
    $searchText: String!
    $countryCode: String
    $sessionToken: String
  ) {
    getPlacesSuggestions(
      searchText: $searchText
      countryCode: $countryCode
      sessionToken: $sessionToken
    ) {
      status
      token
      predictions {
        description
        matched_substrings {
          length
          offset
        }
        place_id
        reference
        structured_formatting {
          main_text
          main_text_matched_substrings {
            length
            offset
          }
          secondary_text
        }
        terms {
          offset
          value
        }
        types
      }
    }
  }
`;

const GET_PLACE_DETAILS = gql`
  query getPlaceDetails($placeId: String!, $sessionToken: String) {
    getPlaceDetails(place_id: $placeId, sessionToken: $sessionToken) {
      status
      token
      placeDetails {
        place_id
        postal_code
        name
        lat
        lng
        formatted_address
        country
        address_components {
          long_name
          short_name
          types
        }
      }
    }
  }
`;

const GEOCODE_DETAILS = gql`
  query performGeocoding($lat: Float!, $lng: Float!) {
    performGeocoding(lat: $lat, lng: $lng) {
      status
      geocodeDetails {
        name
        place_id
        formatted_address
        lat
        lng
        address_components {
          long_name
          short_name
          types
        }
        postal_code
        country
      }
    }
  }
`;

const GET_MAP_API_KEY = gql`
  query getGmapKey {
    getGmapKey {
      status
      key
    }
  }
`;

export {
  SEARCH_AUTO_COMPLETE,
  GET_PLACE_DETAILS,
  GEOCODE_DETAILS,
  GET_MAP_API_KEY,
};
