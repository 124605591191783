import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { COLOR_SPUN_PEARL } from "../../../constants/colors.constants";
import configSelector from "external/store/selectors/configSelector";
import googlePlacesService from "external/services/googlePlaces";
import CrossIcon from "../../../assets/icons/cross.icon";
import Typography from "../../typography/index.component";
import SelectLocationMap from "./select-location-map";
import AddAddressForm from "./add-address-form";
import { useDialog } from "../../../context/DialogContext";
import upsdkService from "external/services/upsdkService";
import {
  ADDRESS_TAG_HOME,
  ADDRESS_TOO_FAR,
  MINIMUM_SEARCH_STRING_LENGTH,
  NO_STORE_FOUND,
} from "../../../constants/enums.constants";
import SearchedAddresses from "./search-address";
import ChevronIcon from "../../../assets/icons/chevron.icon";
import InputField from "../../fields/input-field/index.component";
import { BaseContext } from "../../../context/BaseContext";
import useRouteHook from "../../../hooks/useRoute.hook";
import debounce from "external/helpers/debounce";
import { connect } from "react-redux";
import upsdkSelector from "external/store/selectors/upsdkSelector";
import useGoogleMapsServices from "hooks/useGoogleMapsServices.hook";

import "./index.component.scss";

function AddAddressView({
  primaryColor,
  primaryTextColor,
  secondaryTextColor,
  config,
  subLocality,
  bizInfo,
  fetchUser,
  closeAddressViewFlow,
  handleFlowComplete,
  userData,
  setSelectedAddress,
  setSubLocality,
  fulfilmentType,
  selectedStore,
  upsdk,
  mapKey,
}) {
  const currency = upsdkSelector.getCurrency({ upsdk });
  const [activeTab, setActiveTab] = useState(ADDRESS_TAG_HOME);
  const [position, setPosition] = useState({
    lat: null,
    lng: null,
  });
  const [newlyAddedAddressId, setNewlyAddedAddressId] = useState(null);
  const { showDialog, hideDialog } = useDialog();
  const [isSaveAddressLoading, setSaveAddressLoading] = useState(false);
  const [mapSelectedSubLocality, setMapSelectedSubLocality] = useState("");
  const [mapSelectedLandmark, setMapSelectedLandmark] = useState("");
  const [searchLocation, setSearchLocation] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pincode, setPincode] = useState("");
  const { isMobileView, menuRoute } = useContext(BaseContext);
  const googleMapApiKey = configSelector.getGoogleMapsKey({ config });
  const { t, i18n } = useTranslation();
  const searchInputRef = useRef();
  const { historyPush } = useRouteHook();
  const { fetchAutoSuggestion, fetchGeocodeDetails, fetchPlaceDetails } =
    useGoogleMapsServices();

  const onMapChangeHandler = (params) => {
    const { lat, lng } = params.center;
    setPosition({ lat, lng });
  };

  const onFormClick = () => {
    if (isMobileView) setShowMap(false);
  };

  const validateAddress = ({ lat, lng }) => {
    return new Promise((resolve, reject) => {
      upsdkService
        .getDeliverableStores(lat, lng, "_", fulfilmentType)
        .then((response) => {
          let deliverableStores = [];
          if (response.store) {
            deliverableStores = [response.store];
          }

          if (response.optional_stores.length) {
            deliverableStores = [
              ...deliverableStores,
              ...response.optional_stores,
            ];
          }

          if (deliverableStores.length) {
            deliverableStores = deliverableStores.map((store) => store.id);
          } else {
            reject(NO_STORE_FOUND);
          }

          if (deliverableStores.includes(selectedStore.id)) {
            resolve();
          } else {
            reject(ADDRESS_TOO_FAR);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  useEffect(() => {
    setPosition({
      lat: subLocality?.lat || selectedStore?.lat,
      lng: subLocality?.lng || selectedStore?.lng,
    });
  }, [subLocality, selectedStore]);

  useEffect(() => {
    fetchAndSetLocationDetails();
  }, [position]);

  useEffect(() => {
    const newlyAddededAddress = (userData?.addresses || []).find(
      (address) => address.id === newlyAddedAddressId,
    );
    if (newlyAddededAddress) {
      setSelectedAddress(newlyAddededAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleAddressSelection = ({ place_id }) => {
    if (googleMapApiKey) {
      googlePlacesService.getDetails(place_id).then((addressDetails) => {
        const { lat, lng } = addressDetails;
        setPosition({ lat, lng });
      });
    } else {
      fetchPlaceDetails(place_id, setPosition);
    }
  };

  const fetchAndSetLocationDetails = () => {
    const { lat, lng } = position;
    if (lat && lng) {
      retrieveGeocodeFrom()(lat, lng)
        .then((response) => {
          setMapSelectedSubLocality(response?.formatted_address || "");
          if (response?.postal_code) setPincode(response.postal_code);
          setMapSelectedLandmark(
            response?.address_components?.find(
              (item) => !item?.types?.includes("landmark"),
            )?.short_name,
          );
        })
        .catch((err) => console.log(err, "error"));
    }
  };

  const retrieveGeocodeFrom = () => {
    if (googleMapApiKey) {
      return googlePlacesService.getSubLocality;
    } else {
      return fetchGeocodeDetails;
    }
  };

  const onSubmit = (data) => {
    setSaveAddressLoading(true);
    const lat = position.lat;
    const lng = position.lng;
    validateAddress({ lat, lng })
      .then(() => {
        /**
         * set position here since the user choosen location is valid and the nearest store is same as selected store
         */

        const addressLine1 = data.addressLine1;
        const landmark = data.landmark;
        const city = data.city;
        const pincode = data.pincode;
        let tag = activeTab;
        if (activeTab === "others") {
          tag = data.label;
        }
        let newAddress = {
          lat: lat,
          lng: lng,
          sub_locality: mapSelectedSubLocality,
          address_1: addressLine1,
          landmark: landmark || "",
          city: city || "",
          pin: pincode,
          tag: tag,
        };

        if (userData.guest_checkout) {
          newAddress.phone = userData.customer_phone;
        }

        upsdkService
          .saveAddress(newAddress)
          .then((response) => {
            if (response.data.address_id) {
              const addressId = response.data.address_id;
              setNewlyAddedAddressId(addressId);
              fetchUser();
              setSelectedAddress({ id: addressId, ...newAddress });
              handleFlowComplete();
            }
          })
          .catch((error) => console.log(error, "error save address"))
          .finally(() => {
            setSaveAddressLoading(false);
          });
      })
      .catch((error) => {
        /**
         * don't let the user set a new position here since the user choosen location is invalid and the nearest store is not same as selected store
         */

        /**
         * resets to a valid position
         */
        setPosition({
          lat: subLocality?.lat || selectedStore?.lat,
          lng: subLocality?.lng || selectedStore?.lng,
        });

        if (error === NO_STORE_FOUND) {
          showDialog({
            title: t("header.storeNotDeliverableHeading"),
            description: t("common.noNearByStoreFound"),
            buttonText: t("common.ok"),
            buttonColor: primaryColor,
            onClickCallback: () => {
              hideDialog();
            },
          });
        } else {
          showDialog({
            title: t("common.addressTooFarHeading"),
            description: t("common.addressTooFarDescription"),
            buttonText: t("common.visitMenuPage"),
            buttonColor: primaryColor,
            onClickCallback: () => {
              hideDialog();
              upsdkService.removeStore();
              upsdkService.clearCart();
              setSubLocality(null);
              setSelectedAddress(null);
              historyPush(menuRoute);
            },
          });
        }
        setSaveAddressLoading(false);
      });
  };

  const onLocationSelect = (address) => {
    handleAddressSelection(address);
    setSearchLocation(false);
  };

  const handleBack = () => {
    setShowMap(true);
    setSearchLocation(false);
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
    setAddressSuggestions([]);
  };

  useEffect(() => {
    if (searchLocation && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchLocation]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const googlePlacesAutoComplete = useCallback(
    (address) => {
      if (address.trim()) {
        googlePlacesService
          .search(address, bizInfo.isd_code)
          .then((response) => {
            setAddressSuggestions(response);
          })
          .catch((error) => console.log(error));
      } else {
        setAddressSuggestions([]);
      }
    },
    [bizInfo],
  );

  const handleOnChange = (event) => {
    setSearchQuery(event.target.value);
    const query = event.target.value.trim();
    if (query?.length > MINIMUM_SEARCH_STRING_LENGTH) {
      debouncedFunction(query);
    }
  };

  const debouncedFunction = useCallback(
    debounce((query) => {
      if (googleMapApiKey) {
        googlePlacesAutoComplete(query);
      } else {
        fetchAutoSuggestion(
          query,
          bizInfo.isd_code,
          currency,
          setAddressSuggestions,
        );
      }
    }, 1000),
    [googleMapApiKey, bizInfo.isd_code],
  );
  return (
    <div className="add-address-wrapper">
      {searchLocation ? (
        <Fragment>
          <div className="search-address-heading">
            <InputField
              type="text"
              name="subLocality"
              refForm={searchInputRef}
              className="search-address-input"
              onChange={handleOnChange}
              placeholder="Search your location"
              primaryTextColor={primaryTextColor}
              helperText={t("common.requiredFieldHelperText")}
              inputProps={{
                value: searchQuery,
                disableUnderline: true,
                startAdornment: (
                  <ChevronIcon
                    onClickCallback={handleBack}
                    rotate={i18n.dir() === "rtl" ? "270deg" : "90deg"}
                    className="checkron-back-icon"
                    fill={COLOR_SPUN_PEARL}
                    size={{ width: 16, height: 16 }}
                  />
                ),
                endAdornment: (
                  <Fragment>
                    {searchQuery && (
                      <CrossIcon
                        fill={COLOR_SPUN_PEARL}
                        size={{ width: 12, heigth: 12 }}
                        className="clear-search-icon"
                        onClickCallback={clearSearchQuery}
                      />
                    )}
                  </Fragment>
                ),
              }}
            />
          </div>
          <SearchedAddresses
            bizInfo={bizInfo}
            open={true}
            onLocationSelect={onLocationSelect}
            setSearchLocation={setSearchLocation}
            primaryTextColor={primaryTextColor}
            secondaryTextColor={secondaryTextColor}
            primaryColor={primaryColor}
            addressSuggestions={addressSuggestions}
          />
        </Fragment>
      ) : (
        <Fragment>
          <div className="add-address-heading">
            <div className="add-address-heading-left">
              {!showMap && (
                <ChevronIcon
                  onClickCallback={handleBack}
                  rotate={i18n.dir() === "rtl" ? "270deg" : "90deg"}
                  className="checkron-back-icon"
                  fill={COLOR_SPUN_PEARL}
                  size={{ width: 16, height: 16 }}
                />
              )}

              <Typography
                variant="h1"
                weight="bold"
                className="add-address-heading-text"
                fontColor={primaryTextColor}
              >
                {t("sidebar.selectYourLocation")}
              </Typography>
            </div>
            <div className="add-address-heading-right">
              <CrossIcon
                fill={COLOR_SPUN_PEARL}
                size={{ width: 16, heigth: 16 }}
                className="close-address-drawer-icon"
                onClickCallback={closeAddressViewFlow}
              />
            </div>
          </div>
          {showMap && (
            <div className="add-address-content">
              <SelectLocationMap
                className="select-location-map-wrapper"
                mapKey={googleMapApiKey || mapKey}
                primaryColor={primaryColor}
                position={position}
                onMapChangeHandler={onMapChangeHandler}
              />
            </div>
          )}

          <div
            className="selected-address-info"
            onClick={() => setSearchLocation(true)}
          >
            <div className="heading-wrapper">
              <Typography
                variant="h2"
                weight="bold"
                fontColor={primaryTextColor}
              >
                {mapSelectedLandmark}
              </Typography>
              <Typography variant="h3" weight="bold" fontColor={primaryColor}>
                {t("checkout.change")}
              </Typography>
            </div>
            <Typography
              variant="h4"
              weight="semiBold"
              fontColor={secondaryTextColor}
            >
              {mapSelectedSubLocality}
            </Typography>
          </div>

          <div className="add-address-footer">
            <AddAddressForm
              onFormClick={onFormClick}
              onSubmit={onSubmit}
              primaryColor={primaryColor}
              primaryTextColor={primaryTextColor}
              secondaryTextColor={secondaryTextColor}
              position={position}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleAddressSelection={handleAddressSelection}
              isSaveAddressLoading={isSaveAddressLoading}
              pincode={pincode}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  upsdk: state.upsdk,
});

export default connect(mapStateToProps)(AddAddressView);
