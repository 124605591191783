import React, { lazy } from "react";
import { googleMapStyles } from "./google-map-styles";
import PinContainedIcon from "../../../assets/icons/pin-contained.icon";
import { COLOR_BLACK } from "../../../constants/colors.constants";
const GoogleMapReact = lazy(() => import("google-map-react"));

function SelectLocationMap({
  mapKey,
  className,
  onMapChangeHandler,
  position,
}) {
  const DEFAULT_MAP_ZOOM = 16;

  const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: true,
      gestureHandling: "greedy",
      styles: googleMapStyles,
    };
  };

  return (
    <div className={className}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: mapKey,
          libraries: ["places", "geometry", "drawing", "visualization"],
        }}
        options={createMapOptions}
        onChange={onMapChangeHandler}
        className="select-location-map"
        center={position}
        defaultZoom={DEFAULT_MAP_ZOOM}
      />
      <PinContainedIcon
        size={{ width: 32, height: 42 }}
        fill={COLOR_BLACK}
        className="select-location-marker"
      />
    </div>
  );
}

export default SelectLocationMap;
